import React, { Component } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { strings } from '../../resources/strings';

export class TicketInfo extends Component {
    render() {
        return (
            <Container fluid className="mb-5">
                <Row className="mt-5">
                    <h5>{strings.tickets}</h5>
                </Row>
                <Row>                
                    <h6>{strings.simpleOnBoard}<br />{strings.fullAndHalf}</h6>
                    <p>
                        <ul>
                            <li>{strings.fullAndHalfConditionA}</li>
                            <li>{strings.fullAndHalfConditionB}</li>
                            <li>{strings.fullAndHalfConditionC}</li>
                        </ul>
                    </p>
                </Row>
                <Row>
                    <h6>{strings.monthly}</h6>
                    <p>{strings.monthlyConditions}</p>
                    <p>{strings.monthlyRequirements}
                        <ul>
                            <li>{strings.monthlyRequirementA}</li>
                            <li>{strings.monthlyRequirementB}</li>
                        </ul>
                    </p>
                </Row>
                <Row>
                    <h6>{strings.passAntigoCombatente}</h6>
                    <p>{strings.passAntigoCombatenteDescription}</p>
                    <p>{strings.passAntigoCombatenteUsersAndConditions}
                        <ul>
                            <li>{strings.passAntigoCombatenteConditionA}</li>
                            <li>{strings.passAntigoCombatenteConditionB}</li>
                            <li>{strings.passAntigoCombatenteConditionC}</li>
                            <li>{strings.passAntigoCombatenteConditionD}</li>
                        </ul>
                    </p>
                    <p>{strings.passAntigoCombatenteMoreInfo}<br />
                        <a href="https://www.imt-ip.pt/sites/IMTT/Portugues/Noticias/Paginas/PassedeAntigoCombatente.aspx" target="_blank" rel="noopener noreferrer">{strings.passAntigoCombatenteLink}</a>
                    </p>
                    <p />
                </Row>
                <Row>
                    <h6>{strings.pass418}</h6>
                    <p>
                        <ul>
                            <li>{strings.pass418ConditionA}</li>
                            <li>{strings.pass418ConditionB}</li>
                            <li>{strings.pass418ConditionC}</li>
                            <li>{strings.pass418ConditionD}</li>
                            <li>{strings.pass418ConditionE}</li>
                            <li>{strings.pass418ConditionF}</li>
                        </ul>
                    </p>
                    <p>{strings.pass418Requirements}
                        <ul>
                            <li>{strings.pass418RequirementA}</li>
                            <li>{strings.pass418RequirementB}</li>
                            <li>{strings.pass418RequirementC}</li>
                        </ul>
                    </p>
                    <p>{strings.pass418Renewal}</p>
                    <p>{strings.passes418s23MoreInfo} <br />
                        <a href="https://www.imt-ip.pt/sites/IMTT/Portugues/Tarifas/passe4-18escola-tp/Paginas/Passe4_18-escola_tp.aspx" target="_blank" rel="noopener noreferrer">{strings.pass418}</a>
                    </p>
                    <p />
                </Row>
                <Row>
                    <h6>{strings.passS23ShortName}</h6>
                    <p>
                        <ul>
                            <li>{strings.passS23ConditionA}</li>
                            <li>{strings.passS23ConditionB}</li>
                            <li>{strings.passS23ConditionC}</li>
                            <li>{strings.passS23ConditionD}</li>
                            <li>{strings.passS23ConditionE}</li>
                            <li>{strings.passS23ConditionF}</li>
                        </ul>
                    </p>
                    <p>{strings.passS23Requirements}
                        <ul>
                            <li>{strings.passS23RequirementA}</li>
                            <li>{strings.passS23RequirementB}</li>
                            <li>{strings.passS23RequirementC}</li>
                        </ul>
                    </p>
                    <p>{strings.passS23Renewal}</p>
                    <p>{strings.passes418s23MoreInfo} <br />
                        <a href="https://www.imt-ip.pt/sites/IMTT/Portugues/Tarifas/Passe-sub23-superior-tp/Paginas/Passe-sub23_superior_tp.aspx" target="_blank" rel="noopener noreferrer">{strings.passS23ShortName}</a>
                    </p>
                    <p />
                </Row>
                <Row>
                    <h6>{strings.passCIM}</h6>
                    <p>
                        <ul>
                            <li>{strings.passCIMConditionA}</li>
                            <li>{strings.passCIMConditionB}</li>
                            <li>{strings.passCIMConditionC}</li>
                            <li>{strings.passCIMConditionD}</li>
                            <li>{strings.passCIMConditionE}</li>
                            <li>{strings.passCIMConditionF}</li>
                            <li>{strings.passCIMConditionG}</li>
                        </ul>
                    </p>
                    <p>{strings.passCIMRequirements}
                        <ul>
                            <li>{strings.passCIMRequirementA}</li>
                            <li>{strings.passCIMRequirementB}</li>
                        </ul>
                    </p>
                </Row>
                <Row>
                    <a href="docs/ticket_info_tpac.pdf" target="_blank">{strings.ticketsDownload}</a>
                </Row>
                <Row>
                    &nbsp;
                </Row>
            </Container>
        );
    }
}