import React, { Component } from 'react';
import { Container, Row} from 'react-bootstrap';
import { strings } from '../../resources/strings';

export class PrivacyPolicy extends Component {
    render() {
        return (
            <Container fluid className="mb-5">
                <Row className="mt-5">
                    <h5>{strings.privacyPolicy}</h5>
                </Row>
                <Row className="mt-5">
                    <p>Leia por favor esta Política de Privacidade com atenção, pois no acesso a este site,
                        a disponibilização dos seus dados pessoais implica o conhecimento e aceitação das
                        condições aqui constantes.</p>
                </Row>
                <Row className="mt-5">
                    <p><b>Quem é o responsável pela recolha e tratamento dos dados?</b></p>
                    <p>A Transportes do Alentejo Central S.A é a entidade responsável pela recolha e tratamento
                        dos dados pessoais, podendo, no âmbito da sua actividade, recorrer a entidades por
                        si subcontratadas para a prossecução das finalidades aqui indicadas.</p>
                </Row>
                <Row className="mt-5">
                    <p><b>Quem é o Encarregado de Proteção de dados?</b></p>
                    <p>O encarregado de Protecção de dados é a “<i>Protect Data Consulting</i>”, com morada
                        na Rua de Campolide, n.º 164 D, 1070-037 Lisboa, e os seguintes contacto
                        telefónico: 216083898.</p>
                </Row>
                <Row className="mt-5">
                    <p><b>O que são dados pessoais?</b></p>
                    <p>Dados pessoais são qualquer informação, de qualquer natureza e
                        independentemente do respetivo suporte, incluindo som e imagem, relativa a uma
                        pessoa singular identificada ou identificável. É considerada identificável a pessoa
                        que possa ser identificada direta ou indiretamente, designadamente por referência a
                        um número de identificação ou a um ou mais elementos específicos da sua
                        identidade física, fisiológica, psíquica, económica, cultural ou social.</p>
                </Row>
                <Row className="mt-5">
                    <p><b>Que dados pessoais são recolhidos e tratados?</b></p>
                    <p>A Transportes do Alentejo Central S.A procede à recolha e ao tratamento, entre outros, dos
                        seguintes dados pessoais: nome, número de telefone e endereço de correio
                        eletrónico.</p>
                    <p>A Transportes do Alentejo Central S.A assume que os dados foram fornecidos pelo titular dos
                        mesmos ou que este deu autorização para o efeito e presume que os mesmos são
                        verdadeiros e se encontram atualizados.</p>
                </Row>
                <Row className="mt-5">
                    <p><b>De que forma são recolhidos os seus dados?</b></p>
                    <p>Os dados pessoais podem ser recolhidos através dos seguintes meios:</p>
                    <p>a) Correio electrónico;</p>
                    <p>b) Website;</p>
                    <p>c) Chamadas telefónicas;</p>
                    <p>d) Presencialmente;</p>
                    <p>e) Através de aplicações (“apps”) instaladas em dispositivos móveis.</p>
                    <p>Os dados recolhidos são processados e armazenados informaticamente e no estrito
                        cumprimento da legislação de proteção de dados pessoais sendo armazenados em
                        base de dados específicas, criadas para o efeito, pela Transportes do Alentejo Central, S.A ou
                        pelas entidades por esta subcontratadas.</p>
                    <p>Alguns dados pessoais recolhidos no website são de preenchimento obrigatório e,
                        em caso de falta ou insuficiência desses dados, a Transportes do Alentejo Central S.A
                        poderá não conseguir prestar-lhe os serviços ou as informações por si solicitadas. Em cada
                        caso concreto, a Transportes do Alentejo Central S.A informá-lo-á da natureza
                        obrigatória do fornecimento dos dados pessoais em causa.</p>
                </Row>
                <Row className="mt-5">
                    <p><b>Qual a finalidade dos dados recolhidos?</b></p>
                    <p>Os seus dados destinam-se a integrar um ficheiro de dados pessoais que a
                        Transportes do Alentejo Central S.A poderá utilizar para a prossecução das seguintes
                        finalidades:</p>
                    <p>a) Recrutamento;</p>
                    <p>b) Agenda de contactos;</p>
                    <p>c) Atualização de perfil;</p>
                    <p>d) Envio de newsletters;</p>
                    <p>e) Opinião do Utente;</p>
                    <p>f) Sugestões e Reclamações;</p>
                    <p>g) Pedido de Orçamento;</p>
                    <p>h) Publicidade nos nossos suportes;</p>
                    <p>i) Compra de bilhetes/títulos de transporte;</p>
                    <p>j) Adesão a cartões de transporte;</p>
                    <p>k) Facturas Net;</p>
                    <p>l) Profiling;</p>
                    <p>m) Perdidos e Achados;</p>
                    <p>n) Cumprimento de outras obrigações legais ou regulamentares;</p>
                    <p>o) Realização de estudos de mercado, inquéritos de avaliação e análises
                        estatísticas.</p>
                    <p>Para a prossecução das finalidades acima identificadas a Transportes do Alentejo Central S.A
                        poderá proceder à interconexão dos dados recolhidos, com o propósito de atualizar
                        e completar tais dados.</p>
                </Row>
                <Row className="mt-5">
                    <p><b>Qual é o fundamento para o tratamento dos seus dados?</b></p>
                    <p>Os tratamentos associados às finalidades das alíneas a), d), e l) têm por fundamento
                        o seu consentimento, bem como uso dos dados para marketing direto que podem
                        estar associados às finalidades i) e j).</p>
                    <p>Os tratamentos associados às finalidades das alíneas b), c), e), g), h), i), j), k) e m)
                        têm por fundamento a relação contratual que se estabelece entre si e a empresa.</p>
                    <p>Os tratamentos associados às finalidades f) (Sugestões e Reclamações) e n)
                        (Cumprimento de outras obrigações legais ou regulamentares) têm por fundamento
                        o cumprimento de obrigações legais.</p>
                </Row>
                <Row className="mt-5">
                    <p><b>Com quem são partilhados os seus dados?</b></p>
                    <p>Os dados recolhidos e detidos pela Empresa Transportes do Alentejo Ce ntral S.A
                        poderão ser transmitidos, com respeito pelo dever da confidencialidade e pelo princípio da
                        finalidade que presidiu à sua recolha, para as seguintes entidades:</p>
                    <p>a) Entidades do grupo em que a Transportes do Alentejo Central S.A está inserida:</p>
                    <p>b) Autoridades judiciais ou administrativas, nos casos em que tal cedência seja
                        obrigatória;</p>
                    <p>c) Empresas de recrutamento e seleção;</p>
                    <p>d) Subcontratantes que procederão ao tratamento dos dados por conta da
                        Empresa Transportes do Alentejo Central S.A e de acordo com as finalidades
                        por esta determinadas</p>
                </Row>
                <Row className="mt-5">
                    <p><b>"COOKIES"</b></p>
                    <p>"Cookies" são pequenos pedaços de informações que podem ajudar a identificar o
                        seu browser e que podem armazenar informações, por exemplo, configurações e
                        preferências do Utilizador.</p>
                    <p>A Empresa Transportes do Alentejo Central S.A poderá armazenar cookies no seu
                        dispositivo para personalizar e facilitar ao máximo a navegação, não fornecendo
                        esses mesmos cookies referências ou dados pessoais do Utilizador.</p>
                    <p>Para mais informação deverá ler a nossa Política de Cookies.</p>
                </Row>
            </Container>
        );
    }
}