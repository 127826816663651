import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet';
import { strings } from '../../resources/strings';
import { ticketOfficeIcon } from '../leaflet/StopIcons';

export class TicketOffice extends Component {
    render() {
        return (
            <Container fluid className="mb-5" >
                <Row>
                    <Col lg={4}>
                        <div class="mt-5">
                            <b>{strings.contactHeader}</b><br />
                            <b>Terminal Rodoviário de Évora<br />
                                Av.Tulio Espanca - Terminal Rodoviário<br />
                                7005 - 840 Évora</b><br />
                            <b>{strings.email}:</b> geral@tpac.pt<br />
                            <b>{strings.phone}:</b> 266 048 924<br /><br />
                            <p>{strings.contactEveryday}</p>
                        </div>

                        <div className="map-container-formatter">
                            <MapContainer className="map-container-ticket-office" center={[38.568010, -7.918093]} zoom={16} scrollWheelZoom={true}>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                                />
                                <Marker
                                    key={`to-rodoviaria-de-evora`}
                                    position={[38.568010, -7.918093]}
                                    icon={ticketOfficeIcon}>

                                    <Tooltip>
                                        <strong>Terminal Rodoviário de Évora</strong>
                                    </Tooltip>
                                </Marker>
                            </MapContainer>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div class="mt-5">
                            <b>{strings.contactHeader2}</b><br />
                            <b>Estação de Montemor<br />
                                Rua de São Francisco n.º19 em<br />
                                7050 - 160 Montemor-o-Novo</b><br /><br />
                            {/* 
                            <div class="map-spacing-formatter">
                                <br />
                            </div>
                            <br />
                            <br />
                            */}
                            <p>{strings.contactExceptSatturday}</p>
                        </div>

                        <div className="map-container-formatter">
                            <MapContainer className="map-container-ticket-office" center={[38.64849, -8.21175]} zoom={16} scrollWheelZoom={true}>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                                />
                                <Marker
                                    key={`to-estacao-de-montemor`}
                                    position={[38.64849, -8.21175]}
                                    icon={ticketOfficeIcon}>

                                    <Tooltip>
                                        <strong>Estação de Montemor</strong>
                                    </Tooltip>
                                </Marker>
                            </MapContainer>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}
