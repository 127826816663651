import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Route, Redirect, Switch } from 'react-router';
import { isSafari } from 'react-device-detect';
import { Layout } from './components/Layout';
import { NextDepartures } from './components/nextdepartures/NextDepartures';
import { PlanRoute } from './components/planroute/PlanRoute';
import { Schedules } from './components/schedules/Schedules';
import { SchedulesByStop } from './components/schedules/SchedulesByStop';
import { Contact } from './components/Contact';
import { Messages } from './components/messages/Messages';
import { NotFound } from './components/NotFound';
import { LogoCommand } from '../src/components/commands/LogoCommand';
import './custom.css'
import { About } from './components/about/About';
import { changeLanguage } from "./resources/strings.js";
import { LandingPage } from './components/LandingPage';
import { TicketInfo } from './components/info/TicketInfo';
import { ServiceOnDemand } from './components/info/ServiceOnDemand';
import { TicketOffice } from './components/info/TicketOffice';
import { PrivacyPolicy } from './components/info/PrivacyPolicy';

export default class App extends Component {
    static childContextTypes = {
        getState: PropTypes.func,
        setState: PropTypes.func,
        getLogo: PropTypes.func,
        getMapCenter: PropTypes.func,
        setMapCenter: PropTypes.func,
        recenter: PropTypes.func,
        updateLanguage: PropTypes.func,
        getMenuState: PropTypes.func,
        setMenuState: PropTypes.func
    };

    static displayName = App.name;

    constructor(props) {
        super(props);

        this.state = {
            states: {
                nextDepartures: undefined,
                lineInfo: undefined,
                stopPassingInfo: undefined,
                searchBar: undefined
            },
            commands: {
                logos: new LogoCommand()
            },
            mapCenter: [window.defaultCoordinates.lat, window.defaultCoordinates.lng],
            userLocation: [],
            useUserLocation: false,
            // waitForGeolocationCheck: true,
            isMenuOpen: window.innerWidth < 768 ? false : true,
            logos: {}
        };
    }

    componentDidMount() {
        //Set function to handle when window is resized
        window.onresize = () => this.resize();

        //Check is geolocation is available
        /*if ("geolocation" in navigator) {
            if (!isSafari) {
                navigator.permissions.query({ name: 'geolocation' }).then((result) => {
                    //Used to make app run while user has not yet accepted/denied location access
                    if (result.state === 'prompt') {
                        this.setState({
                            waitForGeolocationCheck: false
                        }, () => this.getUserLocation())
                    } else {
                        this.getUserLocation();
                    }
                });
            } else {
                this.getUserLocation();
            }
        }*/

        this.getLogos();
    }

    componentWillUnmount() {
        window.onresize = () => { };
    }

    resize() {
        if (window.innerWidth < 768) {
            this.setState({
                isMenuOpen: false
            });
        }
    }

    //USER LOCATION / MAP CENTER / RECENTER
    /*getUserLocation() {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                this.setState({
                    mapCenter: [position.coords.latitude, position.coords.longitude],
                    userLocation: [position.coords.latitude, position.coords.longitude],
                    useUserLocation: true,
                    waitForGeolocationCheck: false
                });
            },
            (error) => {
                this.setState({
                    waitForGeolocationCheck: false
                });
            }
        );
    }*/

    getMapCenter() {
        const { mapCenter } = this.state;
        return mapCenter;
    }

    setMapCenter(center, getNearStopsCallback) {
        const { mapCenter } = this.state;

        if (mapCenter[0] === center[0] && mapCenter[1] === center[1]) {
            if ("function" === typeof getNearStopsCallback) {
                getNearStopsCallback();
            }

            return;
        }

        this.setState({
            mapCenter: center
        }, () => {
            if ("function" === typeof getNearStopsCallback) {
                getNearStopsCallback();
            }
        });
    }

    recenter(getNearStopsCallback) {
        /*const { useUserLocation } = this.state;

        if (useUserLocation) {
            this.setMapCenter(this.state.userLocation, getNearStopsCallback);
        } else {*/
        this.setMapCenter([window.defaultCoordinates.lat, window.defaultCoordinates.lng], getNearStopsCallback);
        // }
    }
    //--------------------

    //LOGOS
    getLogos() {
        const { commands } = this.state;
        commands.logos.getLogos((r) => this.getLogosSuccessCallback(r));
    }

    getLogosSuccessCallback(result) {
        this.setState({
            logos: result
        });
    }
    //----------------

    //Update Language
    updateLanguage(languageKey) {
        changeLanguage(languageKey);
        this.setState({});
    }
    //--------------

    //MENU
    getMenuState() {
        const { isMenuOpen } = this.state;
        return isMenuOpen;
    }

    setMenuState() {
        const { isMenuOpen } = this.state;

        this.setState({
            isMenuOpen: !isMenuOpen
        });
    }
    //--------------

    getChildContext() {
        return {
            getState: (a) => this.getComponentState(a),
            setState: (a, b) => this.setComponentState(a, b),
            getLogo: (provider) => this.getLogo(provider),
            getMapCenter: () => this.getMapCenter(),
            setMapCenter: (center, callback) => this.setMapCenter(center, callback),
            recenter: (callback) => this.recenter(callback),
            updateLanguage: (languageKey) => this.updateLanguage(languageKey),
            getMenuState: () => this.getMenuState(),
            setMenuState: () => this.setMenuState()
        };
    }

    getComponentState(which) {
        const { states } = this.state;
        return states[which];
    }

    setComponentState(which, newState) {
        const { states } = this.state;

        states[which] = newState;

        this.setState({
            states: states
        });
    }

    getLogo(provider) {
        const { logos } = this.state;
        if (undefined !== logos[provider]) {
            return `https://www.trimbal.pt/content/logos/${logos[provider]}`;
        }

        return null;
    }

    render() {
        /* const { waitForGeolocationCheck } = this.state;
 
         if (waitForGeolocationCheck) {
             return null;
         }*/

        return (
            <Switch>
                <Redirect exact from='/' to='/home' />
                <LayoutRoute path='/home' component={LandingPage} />
                <LayoutRoute path='/network' component={NextDepartures} />
                <LayoutRoute path='/plan-route' component={PlanRoute} />
                <LayoutRoute path='/schedules/:lineId?/:direction?' component={Schedules} />
                <LayoutRoute path='/schedulesbystop' component={SchedulesByStop} />
                <LayoutRoute path='/contacts' component={Contact} />
                <LayoutRoute path='/news' component={Messages} />
                <LayoutRoute path='/ticket-info' component={TicketInfo} />
                <LayoutRoute path='/ticket-office' component={TicketOffice} />
                <LayoutRoute path='/service-on-demand' component={ServiceOnDemand} />
                <LayoutRoute path='/about' component={About} />
                <LayoutRoute path='/privacypolicy' component={PrivacyPolicy} />
                <LayoutRoute path='*' component={NotFound} />
            </Switch>
        );
    }
}

function LayoutRoute({ component: Component, ...rest }) {
    return (
        <Route {...rest} render={props => <Layout><Component {...props} isMenuOpen={false} /></Layout>} />
    )
};
